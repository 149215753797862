import type { FormParams, PartialRecordError } from '@/types/Form';
import { parsedError } from '@/utils/Helpers';
import { useVuelidate } from '@vuelidate/core';
import { ref } from 'vue';

/**
 * useForm:
 * This function is used to handle form validation using vuelidate
 * It takes the form data and the rules for the form
 * It returns vuelidate object, form data, handleSubmit function, and getErrors function
 *
 * @param {FormData} form: The form data
 * @param {Rules} rules: The rules for the form
 * @returns {v$, form, handleSubmit, getErrors}
 *
 * @example
 * const { VALIDATIONS, PASSWORD_VALIDATION } = useValidation();
 *
 * const { v$, form, handleSubmit, getErrors } = useValidation({
 *   form: {
 *     email: '',
 *     password: '',
 *     confirmPassword: '',
 *   },
 *   rules: {
 *     email: VALIDATIONS.required('Email').email(),
 *     password: PASSWORD_VALIDATION,
 *     confirmPassword: VALIDATIONS.required('Confirm Password').sameAs('Password', 'password'),
 *   },
 * });
 */
export function useForm<FormData>({ form, rules }: FormParams<FormData>) {
  const formData = ref<FormData>(form);

  const v$ = useVuelidate(rules, formData, { $autoDirty: true });

  const handleSubmit = async (callback: () => any) => {
    const result = await v$.value.$validate();

    if (!result) return;
    return callback();
  };

  const getErrors = (otherErrors?: PartialRecordError<FormData>) => {
    return parsedError<FormData>(formData.value, v$, otherErrors);
  };

  return {
    v$,
    form: formData,
    handleSubmit,
    parsedError,
    getErrors,
  };
}
